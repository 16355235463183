@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Quicksand';
}

h1 {
  font-family: 'Cabin', sans-serif;
}

p {
  font-family: 'Roboto';
}
html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}
