.imgSize {
  width: 640px;
  height: 780px;
  filter: brightness(0.5);
}

.carousel-indicators {
  display: none !important;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  top: 325px; /* Cambiar bottom por top y los píxeles que necesites */
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Verdana, sans-serif;
  font-size: 50px;
}
.wrap {
  word-wrap: break-word;
}
